import Layout from "components/layout";
import React from "react";
import Breadcrumb from "components/LegalPolicies/Breadcrumb";
import SEO from "components/seo";
import backToTopIcon from "assets/backToTop.svg";

function Privacy() {
  const data = [
    {
      title: "I. INTRODUCTION",
      description: [
        `This Privacy Policy outlines how Tomedes Ltd. ("we," "us," or "our") collects, processes, and protects the personal information you provide when you visit our website [www.tomedes.com] ("Website"). This policy does not apply to information collected offline or through other means, including third-party websites linked from our site.`,
      ],
    },

    {
      title: "II. INFORMATION WE COLLECT",
      description: [
        `We collect various types of information from visitors to our Website. This may include personal information that can be used to identify you. The types of information we collect include:`,

        "Information You Provide: When you fill out forms on our Website, such as when signing up for newsletters or making inquiries, we may collect information such as your name, email address, phone number, and company name.",

        "Automatically Collected Information: As you navigate our Website, we may collect information about your device, browser, IP address, and browsing patterns. This includes data such as access dates and times, pages viewed, and referring website URLs.",

        "Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience on our Website. Cookies are small text files that are stored on your device. They help us recognize you and remember your preferences for future visits.",
      ],
    },
    {
      title: "III. HOW WE USE YOUR INFORMATION",
      description: [
        `We use the information collected for various purposes, including:`,
      ],

      list: [
        "Providing you with the information, products, and services you request.",
        `Improving our Website's functionality and user experience.`,
        "Sending you relevant communications, such as newsletters or updates.",
        "Analyzing user behavior to enhance our content and services.",
        "Complying with legal obligations and protecting our rights.",
      ],
    },
    {
      title: "IV. INFORMATION SHARING",
      description: [
        `We may share your personal information under certain circumstances:`,
      ],

      list: [
        "With our affiliates and subsidiaries for the purposes outlined in this policy.",
        "With service providers who assist us in operating our Website and providing our services.",
        "To comply with legal requirements or respond to legal requests.",
        "In case of a merger, acquisition, or sale of our assets, where your information may be transferred to the acquiring entity.",
      ],
    },
    {
      title: "V. DATA SECURITY",
      description: [
        `We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute security.`,
      ],
    },

    {
      title: "VI. YOUR RIGHTS",
      description: [`You have the right to:`],

      list: [
        " Access the personal information we hold about you.",
        "Correct any inaccurate or incomplete information.",
        "Withdraw consent for processing, where applicable.",
        "Object to the processing of your information.",
        "Delete your personal information (subject to legal and operational requirements).",
        "Lodge a complaint with a data protection authority.",
      ],
    },
    {
      title: "VII. COOKIES AND TRACKING",
      description: [
        `We use cookies and similar technologies on our Website for various purposes. You can manage your cookie preferences through your browser settings. However, please note that disabling cookies may affect your user experience.`,
      ],
    },
    {
      title: "VIII. CHANGES TO THIS PRIVACY POLICY",
      description: [
        `We may update this Privacy Policy from time to time to reflect changes in our practices and legal requirements. The "Effective as of" date at the top of the policy indicates the latest version. Continued use of our Website constitutes your acceptance of the updated policy.`,
      ],
    },
  ];
  return (
    <>
      <SEO
        title="Privacy Policy - Tomedes"
        description="Review Tomedes' Privacy Policy to understand how we collect, use, and protect your personal data as part of our commitment to transparency and your data rights."
        keywords=""
        slug="/legal/privacy-policy"
      />
      <Layout>
        <div className="max-w-7xl p-4 mx-auto space-y-8 py-12">
          <Breadcrumb path={["Legal Policies", "Privacy Policy"]} />
          <div>
            <h1 className="text-5xl md:leading-none leading-[50px] font-bold">
              Privacy Policy - Tomedes Ltd.
            </h1>
            <p className="font-opensans mt-4">
              Effective as of September 21, 2023
            </p>
          </div>
          <div className="space-y-3">
            <p className="font-opensans leading-8">
              We recommend that you read this Privacy Policy in full to
              understand how we collect, use, and protect your personal
              information. If you have specific questions or concerns, please
              contact us at support@tomedes.com. By using our website, you agree
              to the practices described in this Privacy Policy.
            </p>
          </div>
          <div className="flex flex-col mt-[60px] gap-8">
            {data.map((item, i) => (
              <div key={i} className="rounded-lg mt-10 my-5 text-[#131313]">
                <h1 className="text-2xl font-primary font-bold ">
                  {item.title}
                </h1>

                {item?.description?.map((text, i) => (
                  <p key={i} className="font-opensans leading-8 mt-5 ">
                    {text}
                  </p>
                ))}

                {item?.list &&
                  item?.list?.map((text, i) => (
                    <li key={i} className="font-opensans leading-8 mt-5 ml-5 ">
                      {text}
                    </li>
                  ))}
              </div>
            ))}
          </div>

          <div className="rounded-lg mt-10 my-5 text-[#131313]">
            <h1 className="text-2xl font-primary font-bold leading-9 ">
              IX. CONTACT INFORMATION
            </h1>
            <p className="font-opensans leading-8 mt-5 ">
              If you have questions or concerns about this Privacy Policy, or if
              you wish to exercise your rights, please contact us at
              support@tomedes.com or at the below addresses.
            </p>

            <div className="font-opensans flex flex-col leading-8 mt-5 ">
              <span>Tomedes LLC</span>
              <span>9450 SW Gemini Dr #34540</span>
              <span>Beaverton, OR 97008-7105</span>
              <span>United States</span>
              <span>Call : +1 985 239 0142</span>
            </div>
            <div className="font-opensans flex flex-col leading-8 mt-5 ">
              <span>Tomedes Ltd.</span>
              <span>26 HaRokmim st. Azrieli Business Center,</span>
              <span>Building C, 7th floor, Holon 5885849</span>
              <span>Israel</span>
              <span>Call : +972 (0)72 220 0700</span>
            </div>
            <div className="font-opensans flex flex-col leading-8 mt-5 ">
              <span>Tomedes Ltd.</span>
              <span>7 Bell Yard, London</span>
              <span>WC2A 2 JR</span>
              <span>United Kingdom</span>
              <span>Call: +44 (0)16 1509 6140</span>
            </div>
          </div>
          <div className="flex flex-col items-center mt-[50px]">
            <img
              src={backToTopIcon}
              alt="backIcon"
              className="cursor-pointer"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
            <span className="mt-4 font-primary">Back To Top</span>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default Privacy;
